import React from 'react';
import { Route, Navigate } from 'react-router-dom';

// ProtectedRoute component
const ProtectedRoute = ({ element, isAllowed, redirectPath = '/' }) => {
    isAllowed = localStorage.getItem('isauth') === 'true';  // Check if 'true'
  return isAllowed ? element : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
